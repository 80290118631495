<!-- Attendance toggle -->
<button mat-icon-button (click)="openPanel()" #attendanceOrigin>
    <ng-container *ngIf="attendanceUrl">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-warn-600 text-warn-50 text-xs font-medium">
                1
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:qrcode'"></mat-icon>
</button>

<!-- Attendance panel -->
<ng-template #attendancePanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">{{ 'course.attendance' | transloco}}</div>
            <div class="ml-auto" *ngIf="attendanceUrl">
                <button mat-icon-button [matTooltip]="'Stop attendance'" (click)="stopAttendance()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:stop'"></mat-icon>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Messages -->
            <ng-container *ngIf="attendanceUrl">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <qrcode [qrdata]="attendanceUrl.url" [allowEmptyString]="true" [elementType]="'canvas'"
                        [errorCorrectionLevel]="'M'" [margin]="4" [scale]="1" [width]="200"></qrcode>

                    <!--<div class="mt-5 text-1xl font-semibold tracking-tight">{{ attendanceUrl.url }}</div>-->
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ attendanceUrl.group }}</div>

                    <button mat-button color="primary" (click)="openDialog(AttendanceModal)">{{ 'open-fullscreen' |
                        transloco}}</button>
                </div>

            </ng-container>

            <!-- No Attendance -->
            <ng-container *ngIf="!attendanceUrl">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50" [svgIcon]="'heroicons_outline:qrcode'">
                        </mat-icon>
                    </div>
                    <div class="mt-5 text-2xl text-center font-semibold tracking-tight">{{ 'course.no-attendance' | transloco }}
                    </div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ 'course.no-attendance-msg' |
                        transloco }}</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>

<!-- Attendance Modal -->
<ng-template #AttendanceModal>
    <mat-dialog-content>
        <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
            <qrcode [qrdata]="attendanceUrl.url" [allowEmptyString]="true" [elementType]="'canvas'"
                [errorCorrectionLevel]="'M'" [margin]="2" [scale]="3" [width]="400"></qrcode>

            <!--<div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ attendanceUrl.url }}</div>-->

            <div class="mt-5 text-2xl font-semibold tracking-tight">{{ attendanceUrl.course }}</div>
            <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{ attendanceUrl.group }}</div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button (click)="stopAttendance()" color="warn">
            <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:stop'"></mat-icon> {{ 'stop' |
            transloco }}
        </button>
        <button mat-button matDialogClose color="primary">{{ 'close' | transloco }}</button>
    </mat-dialog-actions>
</ng-template>