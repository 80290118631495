import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'app/core/user/user.service';
import { take } from 'rxjs/operators';
import { environment } from "environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private userEmail: string;
  private isAuthenticated: boolean = false;

  constructor(private http: HttpClient, private userService: UserService) {
    this.userService.user$.pipe(take(1)).subscribe(user => {
      this.userEmail = user ? user.email : null;
      this.isAuthenticated = !!user; // Vérifie si l'utilisateur est authentifié
    });
  }

  trackEvent(eventName: string, url: string, duration: number, message: string = '') {
    if (this.isAuthenticated) {
      const eventData = {
        eventName,
        url,
        duration,
        message,
        userEmail: this.userEmail
      };

      this.http.post(environment.apiUrl + 'track-event', eventData).subscribe();
    }
  }
}
