import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, map, Observable, of, switchMap, tap, take, throwError, filter } from 'rxjs';
import { Attendance, Course, Student, Presence} from 'app/modules/admin/courses/courses.types';
import { environment } from "environments/environment";
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class CourseService {
    private _courses: BehaviorSubject<Course[] | null> = new BehaviorSubject(null);
    private _course: BehaviorSubject<Course | null> = new BehaviorSubject(null);

    private _students: BehaviorSubject<Student[] | null> = new BehaviorSubject(null);

    private _attendance: BehaviorSubject<Attendance | null> = new BehaviorSubject(null);

    private _presences: BehaviorSubject<Presence[] | null> = new BehaviorSubject(null);


    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get courses$(): Observable<Course[]> {
        return this._courses.asObservable();
    }

    get course$(): Observable<Course> {
        return this._course.asObservable();
    }

    get students$(): Observable<Student[]> {
        return this._students.asObservable();
    }

    get attendance$(): Observable<Attendance> {
        return this._attendance.asObservable();
    }

    get presences$(): Observable<Presence[]> {
        return this._presences.asObservable();
    }
    

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the all courses
     */
    getCourses(email, start_date = DateTime.now().toISODate() ,end_date = DateTime.now().plus({ days: 3 }).toISODate() ): Observable<Course[]> {
        this._courses.next(null);
        return this._httpClient.get<Course[]>(environment.apiUrl + 'courses?email=' + email + "&start_date=" + start_date + "&end_date=" + end_date).pipe(
            tap((courses) => {
                this._courses.next(courses);
            })
        );
    }

    /**
     * Get course by id
     * @param aurionId 
     * @returns 
     */
    getCourse(aurionId: string): Observable<Course> {

        if (this._courses.value == null) {
            let email = atob(localStorage.getItem('currentUser'));

            this.getCourses(email).subscribe((result) => {
                this.getCourse(aurionId).subscribe();
            });

        } else {

            return this._courses.pipe(
                map((courses) => {


                    // Find the contact
                    const course = courses.find(item => item.AurionId === aurionId) || null;

                    // Update the contact
                    this._course.next(course);

                    // Return the contact
                    return course;
                }),
                switchMap((course) => {

                    if (!course) {
                        return throwError('Could not found course with id of ' + aurionId + '!');
                    }

                    return of(course);
                })
            );
        }

    }


    /**
     * Get all students by course ID
     * 
     * @param aurionId 
     * @returns 
     */
    getStudents(aurionId) {
        return this._httpClient.get<Student[]>(environment.apiUrl + 'courses/students/' + aurionId).pipe(
            tap((students) => {
                this._students.next(students);
            })
        );
    }

    /**
     * Get attendance by aurion ID
     * 
     * @param aurionId 
     * @returns 
     */
    getAttendance(aurionId) {
        return this._httpClient.get<Attendance>(environment.apiUrl + 'attendance/' + aurionId).pipe(
            tap((attendance) => {
                this._attendance.next(attendance);
            })
        );
    }

    /**
     * Create presence by aurion ID
     * 
     * @param aurionId 
     * @returns 
     */
    createAttendance(aurionId) {
        return this._httpClient.post<Attendance>(environment.apiUrl + 'attendance/create/' + aurionId, {}).pipe(
            map((attendance) => {
                console.log(attendance);
                this._attendance.next(attendance);
            })
        );
    }

    /**
     * Update Attendance
     * @param attendance 
     * @returns 
     */
    updateAttendance(attendance: Attendance) {
        return this._httpClient.patch<Attendance>(environment.apiUrl + 'attendance/' + attendance.course_aurion_id, { attendance }).pipe(
            map((attendance) => {
                this._attendance .next(attendance);
            })
        );
    }

    /**
     * Get students presences
     * 
     * @param aurionId 
     * @returns 
     */
    getPresence(aurionId) {
        return this._httpClient.get<Presence[]>(environment.apiUrl + 'attendance/students/' + aurionId).pipe(
            tap((presence) => {
                this._presences.next(presence);
            })
        );
    }

    /**
     * Set student presence
     * @param StudentId 
     * @param presence 
     * @returns 
     */
    setPresence(StudentId, presence) {
        return this.presences$.pipe(
            take(1),
            switchMap(presences => this._httpClient.post<Presence>(environment.apiUrl + 'attendance/students', {
                StudentId,
                presence
            }).pipe(
                map((updatedPresence) => {

                    // Find the index of the updated presence
                    const index = presences.findIndex(presence => presence.id === StudentId);

                    // Update the presence
                    presences[index] = updatedPresence;

                    // Update the presences
                    this._presences.next(presences);

                    // Return the updated presence
                    return updatedPresence;
                })
            ))
        );
    }


}
