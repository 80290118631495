import { ErrorHandler, Injectable } from '@angular/core';
import { TrackingService } from './tracking.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private trackingService: TrackingService) {}

  handleError(error: any): void {
    const message = error.message ? error.message : error.toString();
    this.trackingService.trackEvent('Unhandled Error', window.location.pathname, 0, message);
    console.error('An error occurred:', message);
  }
}
