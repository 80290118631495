import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, BehaviorSubject, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { Attendance } from './attendance.types';
import { environment } from "environments/environment";
import { DateTime } from "luxon";
import { lowerFirst } from 'lodash';
import { customAlphabet } from 'nanoid'
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoService } from "@ngneat/transloco";
import { CourseService } from 'app/modules/admin/courses/courses.service';

const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 10)

@Injectable({
    providedIn: 'root'
})
export class AttendanceService {
    private _url: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _fullscreen: BehaviorSubject<boolean | false> = new BehaviorSubject(null);
    private _attendance: BehaviorSubject<Attendance | null> = new BehaviorSubject(null);
    private _attendanceID: BehaviorSubject<string | null> = new BehaviorSubject(null);

    private timer: any;
    private interval: number = 5000;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _fuseConfirmationService: FuseConfirmationService,
        private _transloco: TranslocoService,
        private _courseService: CourseService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for url
     */
    get url$(): Observable<any> {
        return this._url.asObservable();
    }

    /**
     * Getter for attendance
     */
    get attendance$(): Observable<Attendance> {
        return this._attendance.asObservable();
    }

    /**
     * Setter & Getter &  for fullscreen
     * 
     * @param value
     */
    set fullscreen(value: boolean)
    {
        // Store the value
        this._fullscreen.next(value);
    }

    get fullscreen$(): Observable<boolean> {
        return this._fullscreen.asObservable();
    }

    /**
     * Setter & Getter &  for currentAttendance
     * 
     * @param value
     */
    set currentAttendanceId(value: string)
    {
        // Store the value
        this._attendanceID.next(value);
    }

    get currentAttendanceId$(): Observable<string> {
        return this._attendanceID.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Start Generate URL
     * 
     * @param AurionId 
     */
    startGenerateURL(AurionId, CourseName, GroupeName) {
        


        if (this._attendance.value && this._attendance.getValue().course_aurion_id == AurionId) {

            let attendance = this._attendance.value;
            attendance.in_progress = true;
            this._attendanceID.next(attendance.course_aurion_id);

            this.updateAttendance(attendance).subscribe(result => {
                let generateUrl, params;
                params = nanoid(6);
                generateUrl = "https://elix.neoma-bs.fr/#/e/" + params;

                this._fullscreen.next(true);

                this.setUrlExpiration({ id: AurionId, course: CourseName, group: GroupeName }, params).subscribe();

                this._url.next({ id: AurionId, course: CourseName, group: GroupeName, url: generateUrl });

                this.timer = setInterval(() => {


                    params = nanoid(6);
                    generateUrl = "https://elix.neoma-bs.fr/#/e/" + params;

                    this.setUrlExpiration({ id: AurionId, course: CourseName, group: GroupeName }, params).subscribe();

                    this._url.next({ id: AurionId, course: CourseName, group: GroupeName, url: generateUrl });

                }, this.interval);
            })


        } else {
            if (this._attendance.value) {
                this.stopGenerateURL();
            }
            this.getAttendance(AurionId).subscribe((result) => {
                this.startGenerateURL(AurionId, CourseName, GroupeName);
            });
        }

    }

    /**
     * Stop Generate URL
     */
    stopGenerateURL() {
        clearInterval(this.timer);
        let attendance = this._attendance.value;
        attendance.in_progress = false;
        
        this.updateAttendance(attendance).subscribe(result => {
            this._url.next(null);
            this._fullscreen.next(false);
            this._attendanceID.next(null);

            /*if(attendance.valid) {
                this.validAttendance(attendance.course_aurion_id, true).subscribe();
            }*/
        })

        // Open the confirmation dialog
        /*const confirmation_valid = this._fuseConfirmationService.open({
            title: this._transloco.translate('course.valid_attendance'),
            message: this._transloco.translate('course.valid_attendance_msg'),
            actions: {
                confirm: {
                    label: this._transloco.translate('valid')
                },
                cancel: {
                    label: this._transloco.translate('cancel')
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation_valid.afterClosed().subscribe((result) => {

            if (result === 'confirmed') {
                attendance.valid = true;
            }
            

            this.updateAttendance(attendance).subscribe(result => {
                this._url.next(null);
                this._fullscreen.next(false);
                this._attendanceID.next(null);

                if(attendance.valid) {
                    //this.validAttendance(attendance.course_aurion_id, true).subscribe();
                }
            })

        });*/




    }


    setUrlExpiration(data, shortUrl) {
        return this._httpClient.post(environment.apiUrl + 'attendance/url', {
            'short_url': shortUrl,
            'data': data,
        }).pipe(
            map((url) => {
                console.log(url);
            }
            ));

    }

    /**
     * Get attendance by aurion ID
     * 
     * @param aurionId 
     * @returns 
     */
    getAttendance(aurionId) {
        return this._httpClient.get<Attendance>(environment.apiUrl + 'attendance/' + aurionId).pipe(
            tap((attendance) => {
                console.log('attendance', attendance);
                if (attendance) {
                    this._attendance.next(attendance);
                } else {
                    this._courseService.getCourse(aurionId).subscribe((data) => {
                        this.createAttendance(aurionId, data).subscribe();
                    });
                }
            })
        );
    }

    /**
     * Create attendance by aurion ID
     * 
     * @param aurionId 
     * @returns 
     */
    createAttendance(aurionId, data) {
        return this._httpClient.post<Attendance>(environment.apiUrl + 'attendance/create/' + aurionId, { data }).pipe(
            map((attendance) => {
                console.log(attendance);
                this._attendance.next(attendance);

                this._courseService.getPresence(aurionId).subscribe();

            })
        );
    }

    /**
     * Update attendance
     * 
     * @param attendance 
     * @returns 
     */
    updateAttendance(attendance: Attendance) {
        return this._httpClient.patch<Attendance>(environment.apiUrl + 'attendance/' + attendance.course_aurion_id, { attendance }).pipe(
            map((attendance) => {
                this._attendance.next(attendance);
            })
        );
    }

    validAttendance(aurionId, stop:boolean = false) {
        return this._httpClient.post<Attendance>(environment.apiUrl + 'attendance/valid/' + aurionId, {}).pipe(
            map((attendance) => {
                if(!stop) {
                    this._attendance.next(attendance);
                } else {
                    this._attendance.next(null);
                }
            })
        );
    }
}
