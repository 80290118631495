import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { FuseSplashScreenService } from "@fuse/services/splash-screen";
import { async, asyncScheduler, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

@Component({
  selector: "landing-home",
  templateUrl: "./home.component.html",
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingHomeComponent implements OnInit {
  isIframe = false;
  loginDisplay = true;
  isLogin = false;
  showLogin = false;

  private readonly _destroying$ = new Subject<void>();

  /**
   * Constructor
   */
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private msalService: MsalService, private msalBroadcastService: MsalBroadcastService, private http: HttpClient, private splashScreen: FuseSplashScreenService) {}

  ngOnInit() {
    //this.splashScreen.show();

    console.log("Home Page");
    
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((msalEvent) => {
        // Do something with event payload here
        
        this.setLoginDisplay();
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        //console.log("inside app.component", this.msalService);
        //console.log('showing info: Name: ',this.msalService.instance.getActiveAccount()[0]['name']);
        //sessionStorage.setItem('msalService', this.msalService.instance.getActiveAccount()[0]['name']);
        this.setLoginDisplay();
      });

    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.LOGIN_START),
    )
    .subscribe((result: EventMessage) => {
      console.log("RESULT :", result);
      this.isLogin = true;
    });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  setLoginDisplay() {
   
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;

    if(!this.loginDisplay) {
      this.splashScreen.hide();
    }
  }

 
}
