
<div class="fixed w-full h-full -left-30 -bottom-30 opacity-5 -z-1">    
    <img
        class="w-auto h-full"
        src="assets/images/logo/logo-elix-bg.svg"
        alt="Neoma BS">
</div>

<div class="flex flex-col items-center justify-center w-full h-full p-10">

    <div class="w-full max-w-3xl">
        <div class="flex items-center flex-col max-w-none mx-auto prose prose-sm">
            <div class="flex items-center justify-start w-full">    
                <img
                class="w-40"
                src="assets/images/logo/logo-elix.svg"
                alt="Elix">
            </div>
            

            <div class="login" *ngIf="loginDisplay">
                <div class="spinner">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </div>

            <ng-container *ngIf="loginDisplay">
                <h3>{{ 'home-get-info' | transloco }}</h3>
                <p class="text-justify">
                    {{ 'home-get-info-2' | transloco }}
                </p> 
            </ng-container>

            <ng-container *ngIf="!loginDisplay">
                <h3>{{ 'home' | transloco }}</h3>
                <p class="text-justify">
                    {{ 'home2' | transloco }}
                </p> 
            </ng-container>


            
        </div>
        
        <div class="text-center" *ngIf="!loginDisplay">
            <a
                class="mt-8"
                mat-flat-button
                (click)="login()"
                >
                <img
                class="w-50"
                src="assets/images/logo/signin_light.svg"
                alt="Sign-In with microsoft">
                
            </a>
        </div>


    
        <div class="flex items-center justify-end w-full mt-10">     
            <img
            class="w-30"
            src="assets/images/logo/logo-text.svg"
            alt="Neoma BS">
        </div>

       
        
        

    </div>    
</div>
