import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { AttendanceService } from 'app/layout/common/attendance/attendance.service';
import { TranslocoService } from "@ngneat/transloco";

import { Course } from 'app/modules/admin/courses/courses.types';
import { CourseService } from 'app/modules/admin/courses/courses.service';

import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MatDialog } from '@angular/material/dialog';
import { stringify } from 'crypto-js/enc-base64';


@Component({
    selector: 'attendance',
    templateUrl: './attendance.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'attendance'
})
export class AttendanceComponent implements OnInit, OnDestroy {
    @ViewChild('attendanceOrigin') private _attendanceOrigin: MatButton;
    @ViewChild('attendancePanel') private _attendancePanel: TemplateRef<any>;
    @ViewChild('AttendanceModal', { static: true }) _attendanceModal: TemplateRef<any>;

    inProgress: boolean = false;
    attendanceUrl: any = null;
    course: Course;

    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _attendanceService: AttendanceService,
        private _courseService: CourseService,
        private dialog: MatDialog,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private _transloco: TranslocoService,
        private _fuseConfirmationService: FuseConfirmationService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Subscribe to course changes
        this._courseService.course$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((course: Course) => {
                this.course = course;

                this._changeDetectorRef.markForCheck();
            });

        // Url generate
        this._attendanceService.url$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((attendanceUrl) => {

                this.attendanceUrl = attendanceUrl;

                this._changeDetectorRef.markForCheck();
            });

        // Fullscreen Event
        this._attendanceService.fullscreen$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((value) => {

                if(value) {
                    this.openDialog(this._attendanceModal)
                } else {
                    this.closeDialog();
                }
               
                
            });
              
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the attendande panel
     */
    openPanel(): void {
        // Return if the messages panel or its origin is not defined
        if (!this._attendancePanel || !this._attendanceOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._attendancePanel, this._viewContainerRef));
    }

    /**
     * Close the messages panel
     */
    closePanel(): void {
        if (this._overlayRef) {
            this._overlayRef.detach();
        }
    }


    /**
     * Stop Attendance generate URL
     */
    stopAttendance(): void {        
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title: this._transloco.translate('course.stop_attendance'),
            message: this._transloco.translate('course.stop_attendance_msg'),
            actions: {
                confirm: {
                    label: this._transloco.translate('stop')
                },
                cancel: {
                    label: this._transloco.translate('cancel')
                }
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            // If the confirm button pressed...
            if (result === 'confirmed') {
                this._attendanceService.stopGenerateURL();
            }
        });
    }

    /**
     * Open Dialog for QRcode
     * @param templateRef 
     */
    openDialog(templateRef: TemplateRef<any>): void {
        this.dialog.open(templateRef).afterClosed()
            .subscribe((res) => {
                this._attendanceService.fullscreen = false;
            });
        this.closePanel();
    }

    closeDialog(): void {
        this.dialog.closeAll();
        this.closePanel();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._attendanceOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }


}
