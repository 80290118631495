// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://dev.admin-elix.eclipse360.fr/api/',
    prodUrl: 'https://dev.elix.eclipse360.fr',
    sso :  {
        clientId : 'b8489ba7-702d-4400-ac48-8e025b72aad1',
        authority : 'https://login.microsoftonline.com/ea847b31-dc73-4e64-b8e9-54cb237688f3',
        redirectUri : 'https://dev.elix.eclipse360.fr'
    }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
