import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { MsalModule, MsalService, MsalGuard, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType, BrowserCacheLocation } from "@azure/msal-browser";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from "environments/environment";
import { ServiceWorkerModule } from '@angular/service-worker';
import { GlobalErrorHandler } from 'app/core/tracking/global-error-handler';
import { HttpTrackingInterceptor } from 'app/core/tracking/http-tracking.interceptor';

import {
    MqttModule,
    IMqttServiceOptions
  } from 'ngx-mqtt';

  export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
    hostname: 'mqtt.learningexperience.fr',
    path: '/mqtt',
    protocol : 'wss'
  };


const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: true
};

const isIE = window.navigator.userAgent.indexOf('MSIE') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        MqttModule.forRoot(MQTT_SERVICE_OPTIONS),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        MsalModule.forRoot( new PublicClientApplication({ // MSAL Configuration
            auth: {
                clientId: environment.sso.clientId,
                authority: environment.sso.authority,
                redirectUri: environment.sso.redirectUri,
                postLogoutRedirectUri: environment.sso.redirectUri,
                navigateToLoginRequestUrl: true
            },
            cache: {
                cacheLocation : 'localStorage', //BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE, // set to true for IE 11
            }/*,
            system: {
                loggerOptions: {
                    loggerCallback: () => {},
                    piiLoggingEnabled: false
                }
            }*/
        }), {
            interactionType: InteractionType.Redirect, // MSAL Guard Configuration
            authRequest: {
              scopes: ['User.Read']
            },
            //loginFailedRoute: "/login-failed" 
        }, {
            interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
            protectedResourceMap : new Map([
                ['https://graph.microsoft.com/v1.0/me',['User.Read']]
            ])
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production
          })
    ],
    providers: [
        //{ provide: HTTP_INTERCEPTORS, useClass: HttpTrackingInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        MsalGuard,
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap   : [
        AppComponent,
        MsalRedirectComponent
    ]
})
export class AppModule
{
}
