import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import { InteractionStatus, RedirectRequest, EventMessage, EventType } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AuthService } from 'app/core/auth/auth.service';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import { environment } from "environments/environment";
import { SwUpdate } from '@angular/service-worker';
import { TrackingService } from 'app/core/tracking/tracking.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "msal-angular-tutorial";
  activeUser: string | undefined = "Unknown user";
  isAuthenticated: boolean = false;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  updateAvailable: boolean = false;
  private previousUrl: string;
  
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private broadcastService: MsalBroadcastService, private msalService: MsalService, private http: HttpClient,  private _authService: AuthService, private _router: Router, private _activatedRoute: ActivatedRoute, private splashScreen: FuseSplashScreenService, private updates: SwUpdate, private trackingService: TrackingService) {
    if (this.updates.isEnabled) {
      this.checkForUpdates();
    }
  }

  checkForUpdates() {
    this.updates.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_READY') {
        this.updateAvailable = true;
      }
    });
  }

  reloadPage() {
    this.updates.activateUpdate().then(() => window.location.reload());
  }

  ngOnInit() {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Capturer l'URL actuelle comme URL précédente
        this.previousUrl = event.url;
      }

      if (event instanceof NavigationEnd) {
        // Enregistrer l'événement avec l'URL précédente et l'URL actuelle
        this.trackingService.trackEvent(
          'Route Change',
          event.urlAfterRedirects,
          0,
          `From: ${this.previousUrl}, To: ${event.urlAfterRedirects}`
        );
      }
    });

    //this.splashScreen.show();
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((msalEvent) => {
        // Do something with event payload here
        const totalPayload = msalEvent.payload;
        const accessToken = msalEvent.payload["accessToken"];
        const idTokenClaims = msalEvent.payload["idTokenClaims"];
        const idToken = msalEvent.payload["idToken"];
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("idToken", idToken);
        //console.log('inside app.component, broadcastService.msalSubject$:: ',this.msalService);
        //this.setAuthenticationStatus();
        this.setLoginDisplay();
      });

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        //console.log("inside app.component", this.msalService);
        //console.log('showing info: Name: ',this.msalService.instance.getActiveAccount()[0]['name']);
        //sessionStorage.setItem('msalService', this.msalService.instance.getActiveAccount()[0]['name']);
        this.setAuthenticationStatus();
        this.setLoginDisplay();
      });
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  logout() {
    // Add log out function here
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.sso.redirectUri,
    });
  }

  setAuthenticationStatus(): void {
    let activeAccount = this.msalService.instance.getActiveAccount();
    console.log(activeAccount);
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }
    if (this.msalService.instance.getActiveAccount()) {
      this.getProfile();
    }

    this.isAuthenticated = !!activeAccount;
    this.activeUser = activeAccount?.username;

    if(!this.isAuthenticated) {
      this.splashScreen.hide();
    }
  }

  setLoginDisplay() {
    this.splashScreen.hide();
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;

    if(!this.loginDisplay) {
      this.splashScreen.hide();
    } else  {
      //this.splashScreen.show();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getProfile() {
    this.http.get("https://graph.microsoft.com/v1.0/me?$select=displayName,givenName,surname,mail,onPremisesExtensionAttributes").subscribe((response : any) => {
      console.log(response);
        let statut = response.onPremisesExtensionAttributes.extensionAttribute8;
        let aurionID = response.onPremisesExtensionAttributes.extensionAttribute9;

        // Suivi de l'événement de réception de la présence
        this.trackingService.trackEvent('Login SSO', '', 0, `Status: ${JSON.stringify(response)}`);

      // Sign in
      this._authService.signIn({aurionID: aurionID, email: response.mail, password: "password", type: statut, nom:response.surname , prenom : response.givenName })
      .subscribe(
          (result) => {
              let redirectURL;
              
              if (['Enseignant Permanent', 'Intervenant Extérieur', 'Administratif', 'IT-Prestataire', 'Prestataire'].includes(statut)) {
                  redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-teacher';
              }

              if (statut == 'Apprenant') {

                
                  redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-student';
              }

              // Navigate to the redirect url
              this._router.navigateByUrl(redirectURL);

          }
        );
    });
  }
}
